/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeicons/primeicons.css";
// @import "../node_modules/prismjs/themes/prism-coy.css";
// @import "../node_modules/@fullcalendar/core/main.min.css";
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
// @import "../node_modules/quill/dist/quill.snow.css";

// Theme import
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";

$info-color: #0288D1;

html {
    font-size: 14px;
}

body {
    background-color: #abc9fb; //#609af8;
}

.p-menu {
    font-size: 16px;
}

.p-menubar {
    font-size: 16px;
}

p-menubarsub {
    z-index: 100;
}

.p-button {
    font-size: 16px;
}

th {
    font-size: 12px;
}

td {
    font-size: 12px;
}

.action-icons {
    margin-right: 0.25em;
}

.full-width {
    width: 100%;
}

.unconfirmed-chip {
    background: var(--indigo-600);
    color: white;
}

.incoming-chip {
    background: $info-color;
    color: white;
}

.active-chip {
    background: var(--primary-color);
    color: white;
}

.completed-chip {
    background: var(--green-600);
    color: white;
}

.cancelled-chip {
    background: var(--pink-600);
    color: white;
}

.deactive-chip {
    background: var(--pink-600);
    color: white;
}

.closed-chip {
    background: var(--bluegray-600);
    color: white;
}

// Copying fonts used in primeng components
h1, h2, h3, h4, h5 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-scrollpanel {
    p {
        padding: .5rem;
        line-height: 1.5;
        margin: 0;
    }

    &.custombar {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--layer-1);
        }

        .p-scrollpanel-bar {
            background-color: var(--bluegray-600);
            opacity: 1;
            transition: background-color .2s;

            &:hover {
                background-color: var(--primary-color);
            }
        }
    }
}

.new-contact-panel {
    margin: 2em;
}

.debted-number {
    color: var(--pink-800);
    font-weight: bold;
}

.bold-label {
    font-weight: bold;
}

.highlighted1 {
    background-color: var(--yellow-100) !important;
}
